
import { FC, MouseEvent} from 'react';
import SpriteIcon from '../shared/sprite-icon';
import { useTranslation } from '../../hooks/use-translation';

interface MainDownloadBtnProps {
  appTitle: string;
  btnLabel: string;
  url: string;
  className: string;
  icon?: string;
  handleClickDownload: (event: MouseEvent<HTMLElement>) => void;
}

export const MainDownloadBtn: FC<MainDownloadBtnProps> = (props): 
  JSX.Element => {
  const {
    appTitle,
    btnLabel,
    url,
    className,
    icon,
    handleClickDownload
  } = props;

  const { t } = useTranslation();

  return (
    <a
      href={url}
      onClick={(event: MouseEvent<HTMLElement>) => handleClickDownload(event)}
      className={className}
      download={t('appInstaller', { title: appTitle })}
    >
      {icon && (
        <svg>
          <SpriteIcon
            svgClassName='icon'
            icon={
              icon.toLowerCase()
            }
          />
        </svg>
      )}
      {btnLabel}
    </a>
  );
};
