'use client';

import { useEffect, useState } from 'react';
import { DownloadModal } from './download-modal';
import useBus from '../../hooks/use-bus';
import { AppBusEventType } from '../../interfaces/bus-events';
import { OwApp } from '../../interfaces/domain/ow-app.modal';

export const DownloadModalWrapper = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentApp, setCurrentApp] = useState<OwApp>();
  const [overrideUrl, setOverrideUrl] = useState<string>(null);

  useBus(AppBusEventType.App.ShowDownloadModal, event => {
    const { data } = event;
    const currentApp = data.currentApp as OwApp;
    setCurrentApp(currentApp);

    setIsOpen(data.isOpen);
    if (data.url) {
      setOverrideUrl(data.url);
    } else {
      setOverrideUrl(null);
    }
  });

  useEffect(() => {
    const onScroll = (): void => {
      if (!isOpen) {
        return;
      }

      setIsOpen(false);
    };

    document.addEventListener('scroll', onScroll);

    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, [isOpen]);

  const handleToggleOpen = (): void => {
    setIsOpen(cur => !cur);
  };

  const handleClose = (): void => {
    setIsOpen(false);
  };

  return (
    <DownloadModal
      isOpen={isOpen}
      onClickToggleOpen={handleToggleOpen}
      onClickClose={handleClose}
      currentApp={currentApp}
      url={overrideUrl}
    />
  );
};
