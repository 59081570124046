
import { FC, MouseEvent} from 'react';
import SpriteIcon from '../shared/sprite-icon';
import { useTranslation } from '../../hooks/use-translation';
import { AppDownload } from '../../interfaces/domain/ow-app.modal';

interface MultiDownloadListProps {
  appTitle: string;
  dlData: AppDownload[];
  skipMain?: boolean;
  handleClickDownload:
  (event: MouseEvent<HTMLElement>, overrideUrl: string) => void;
}

export const MultiDownloadList: 
FC<MultiDownloadListProps> = (props): JSX.Element => {
  const {
    appTitle,
    dlData,
    skipMain = false,
    handleClickDownload
  } = props;

  const { t } = useTranslation();

  const groupedDownloads = dlData
    ?.slice(skipMain ? 1 : 0)
    .reduce<Record<string, AppDownload[]>>((acc, item) => {
      if (!acc[item.platform]) {
        acc[item.platform] = [];
      }
      acc[item.platform].push(item);
      return acc;
    }, {});

  return (
    <ul className='multi-options-list'>
      {Object.entries(groupedDownloads).map(([platform, dlItems]) => (
        <li key={platform} className='option-item'>
          {dlItems.map((dlItem, i) => {
            return (
              <a
                key={i}
                className='option-item-url'
                href={dlItem.url}
                download={t('multiAppInstaller', {
                  title: appTitle,
                  platform: dlItem.platform,
                })}
                onClick={(event: MouseEvent<HTMLElement>) =>
                  handleClickDownload(event, dlItem.url)
                }
              >
                <svg>
                  <SpriteIcon icon={dlItem.platform.toLowerCase()} />
                </svg>
                {dlItem.label}
              </a>
            );
          })}
        </li>
      ))}
    </ul>
  );
};
