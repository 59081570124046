import { FC, useEffect, useRef } from 'react';
import { useClassName } from '../../hooks/use-class-name';
import SpriteIcon from '../shared/sprite-icon';
import { useTranslation } from '../../hooks/use-translation';
import { useBuildDownloadUrl } from '../../hooks/use-download-url-builder';
import lottie from 'lottie-web';
import downLoadAnimation from './animations/download-icon.json';
import Utils from '../../utils/utils';
import { useAnalytics } from '../../hooks/use-analytics';
import { useOS } from '../../hooks/use-os';
import { OwApp } from '../../interfaces/domain/ow-app.modal';

// -----------------------------------------------------------------------------
interface Props {
  isOpen: boolean;
  onClickToggleOpen: () => void;
  onClickClose: () => void;
  currentApp: OwApp;
  url?: string;
}

// -----------------------------------------------------------------------------
export const DownloadModal: FC<Props> = ({
  isOpen,
  onClickToggleOpen,
  onClickClose,
  currentApp,
  url = null,
}): JSX.Element => {
  const analytics = useAnalytics();
  const OS = useOS();
  const animIconContainer = useRef<HTMLDivElement>(null);
  const currentAppId = currentApp?.id ?? null;
  const currentAppTitle = currentApp?.title ?? null;
  const constructedUrl = useBuildDownloadUrl({
    name: currentAppTitle,
    extensionId: currentAppId,
    utmContent: 'new-light',
  });

  const downloadUrl = url ? url : constructedUrl;
  const handleClickDownload = (event: React.MouseEvent<HTMLElement>): void => {
    Utils.InstallerDownloadOverwrite(event, currentAppId, url);

    analytics.track('appstore_download_clicked', {
      appId: currentAppId,
      appName: currentAppTitle,
      component: 'Download Modal',
      platform: 'Web',
    });

    analytics.gtagPush({
      event: 'app_download_cta',
      app_name: currentAppTitle,
      app_id: currentAppId,
      app_os: OS,
    });
  };

  const { t } = useTranslation();
  const className = useClassName(
    {
      'modal-is-open': isOpen,
    },
    'download-modal'
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      lottie.loadAnimation({
        container: animIconContainer.current, // Required
        animationData: downLoadAnimation, // Required
        renderer: 'svg', // Required
        loop: true, // Optional
        autoplay: true, // Optional
        name: 'download-icon', // Name for future reference. Optional.
      });
    }, 2000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div className={className}>
      <div className='download-modal-main'>
        <button
          className='clean-icon-btn close-modal'
          onClick={onClickToggleOpen}
        >
          <SpriteIcon icon='closeSmall' />
        </button>

        <div className='download-modal-content'>
          <div>
            <div
              id='download-icon'
              className='anim-icon'
              ref={animIconContainer}
            />
            <h2>{t('downloadStart')}</h2>
            <p>
              {t('ifItDoesnt')}
              <a
                onClick={handleClickDownload}
                href={downloadUrl}
                download={t('appInstaller')}
              >
                {t('dlHere')}
              </a>
            </p>
          </div>

          <div>
            <p
              className='small'
              dangerouslySetInnerHTML={{
                __html: t('dlSupport'),
              }}
            />
          </div>
        </div>
      </div>
      <div className='bg' onClick={onClickClose} />
    </div>
  );
};
